import { useContext, createContext, useState } from "react";
import axios from "axios";
import { BASE_URL } from '../constant/index';
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userEmail, setUserEmail] = useState(localStorage.getItem("userEmail") || null);
  const [userMobile, setUserMobile] = useState(localStorage.getItem("userMobile") || null);
  const [userName, setUserName] = useState(localStorage.getItem("userName") || null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();
  const loginAction = async (userInput) => {
    try {
      const { data, status } = await axios.post(BASE_URL+'/loginUser', userInput);
      if (status === 200 && data) {
        setUserEmail(data?.userEmail);
        setToken(data?.token);
        setUserMobile(data?.userMobile);
        setUserName(data?.userName);
        localStorage.setItem("userName", data.userName);
        localStorage.setItem("userMobile", data.userMobile);
        localStorage.setItem("userEmail", data.userEmail);
        localStorage.setItem("site", data.token);
        navigate("/home");
        return;
      }
    } catch (err) {
      return err.response;
    }
  };

  const logOut = () => {
    setUserEmail(null);
    setUserMobile(null);
    setToken(null);
    setUserName(null);
    localStorage.removeItem("site");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userMobile");
    localStorage.removeItem("userName");
    navigate("/home");
  };

  return (
    <AuthContext.Provider value={{ token, userEmail, userMobile, userName, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};