import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TradeRecommendations from "../../TradeRecommendation/TradeRecommendations";
import MarketDashboard from "../../MarketDashboard/MarketDashboard";
import PatternRecognition from "../../PatternRecognition/PatternRecognition";
import { useAuth } from "../../../../context/AuthProvider";
import { ADMIN_USERS } from '../../../../constant/index';

const a11yProps = (index) => ({
  id: `services-tab-${index}`,
  'aria-controls': `services-tabpanel-${index}`,
});

const Content = () => {
  const { userEmail } = useAuth();
  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();
  const [value, setValue] = React.useState(pathName);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  React.useEffect(() => {
    if(ADMIN_USERS.includes(userEmail)) {
      if (pathName === '/home') {
        navigate('/tradeRecommendations');
      } else if (!['/tradeRecommendations', '/marketDashboard', '/patternRecognition'].includes(pathName)) {
        setValue('/tradeRecommendations');
      } else {
        setValue(pathName);
      }
    } else {
      navigate('/marketDashboard');
    }
    
  }, [pathName, navigate]);

  return (
    <div className="container py-5">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={value} 
              onChange={handleChange} 
              aria-label="home page content" 
              TabIndicatorProps={{
                  style: {
                    backgroundColor: "black",
                  }
              }}
            >
              <Tab label="Market Dashboard" {...a11yProps(1)} to="/marketDashboard" value="/marketDashboard" component={Link} />
              <Tab label="Pattern Recognition" {...a11yProps(2)} to="/patternRecognition" value="/patternRecognition" component={Link} />
              {
                ADMIN_USERS.includes(userEmail) && (
                  <Tab label="Futures Trades" {...a11yProps(0)} to="/tradeRecommendations" value="/tradeRecommendations" component={Link} />
                )
              }
            </Tabs>
        </Box>
        <Box sx={{ padding: 2 }}>
          {value === '/tradeRecommendations' && <TradeRecommendations />}
          {value === '/marketDashboard' && <MarketDashboard />}
          {value === '/patternRecognition' && <PatternRecognition />}
        </Box>
      </Box>
    </div>
  );
}

export default Content;