import React, { useState } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { MemoizedFeaturesSection } from './FeatureSection/FeatureSection';
import OtpVerify from './OtpVerify';
import { useAuth } from "../../../context/AuthProvider";
import { IMAGE_ALIGN_LEFT, IMAGE_ALIGN_RIGHT, BASE_URL } from '../../../constant';
import Star from '../../../assets/Star.svg';
import banner from '../../../assets/banner-image-1.png';
import Avatar from '../../../assets/avatar-group.png';
import mdbMockup from '../../../assets/market-dashboard-mockup.png';
import prsMock from '../../../assets/pattern-recognition-screen.png';
import coaching from '../../../assets/1-1-coaching.png';
import coaching_3 from '../../../assets/homepage-coaching-3.png';
import coaching_2 from '../../../assets/homepage-coaching-2.png';
import Content from './LoggedIn/Content';

const Home = () => {
	const { userEmail } = useAuth();
	const [openModal, setOpen] = useState(false);
	const [primaryPhone, setPhone] = useState('');
	const [ errorMsg, setErrorMsg ] = useState('');

	const onPhoneChange = (e) => {
		setPhone(e.target.value);
	}

	const handleOpen = async () => {
		if(!primaryPhone) {
			setErrorMsg("Please enter a valid phone number!");
			return;
		}

		try {
			await axios.post(BASE_URL+'/sendOtp', { primaryPhone: '+91 '+primaryPhone });
			setOpen(true);
		} catch(err) {
			setErrorMsg(err.response.data.message);
		}
	}

    const handleClose = () => setOpen(false);

	if(userEmail) {
		return (
			<Content />
		)
	}

	return (
		<>
			<div className="container py-5">
				{
                    errorMsg && (
                        <Stack sx={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} spacing={2}>
                            <Alert severity="error" onClose={() => setErrorMsg('')}>{errorMsg}</Alert>
                        </Stack>
                    )
                }
				<div className="row">
					<div className="col px-4">
						<h1 className="main-heading" data-aos="fade-up">Learn to trade like a PRO!</h1>
						<h2 className="subHeading" data-aos="fade-up" data-aos-delay="400">Get the mentorship from stock trading experts, attend 1:1 coaching sessions, FREE access to trading tools, free training and webinars on trading topics to help you gain confidence in trading like a pro.</h2>
						<div id="input-group" className="input-group input-group-lg">
							<span className="input-group-text" id="basic-addon1">+91</span>
							<input 
								id="phoneNumber" 
								name="phoneNumber" 
								type="tel"
								value={primaryPhone}
								onChange={onPhoneChange}
								className="form-control" 
								placeholder="Enter your mobile number" 
								aria-label="mobile" 
								aria-describedby="basic-addon1" 
							/>
							<button id="phone-button" className="phoneNumber btn btn-primary button-cus" type="submit" onClick={handleOpen}>Yes! Sign me up</button>
							<div className="form-text" id="basic-addon4">By signing up, you will have access to our ready-to-use Patterns, use fundamental and technical analysis tools to help you trade with confidence. You may also receive a call from an capitaltrends representative to help answer your questions for trading. </div>
						</div>
						<div className="avatar-group"> 
							<div className="row">
								<div className="col-auto">
									<div>
										<ul className="avatars">
											<li>
												<img src={Avatar} alt="avatar "/>
											</li>
										</ul>
									</div>
								</div>

								<div className="col-auto">
									<div className="stars-group">
										<span>
										<img src={Star} alt='star' />
										</span>
										<span>
										<img src={Star} alt='star' />
										</span>
										<span>
										<img src={Star} alt='star' />
										</span>
										<span>
										<img src={Star} alt='star' />
										</span>
										<span>
										<img src={Star} alt='star' />
										</span>
										<span>
											5.0
										</span>
									</div>
									<span>from 200+ reviews</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col px-4">
						<img src={banner} className="d-block w-100" alt="banner-section-one" />
					</div>
				</div>
			</div>
				
			<div className="container">
				<div className="hr" />
			</div>

			<MemoizedFeaturesSection 
				heading = "Powerful tools for Traders"
				description = "Ready to use pattern recognition charts that you can use for your trading analysis. Helps you better prepare foundational questions - which stock to buy, and when to buy!"
				sectionOneText = "Pattern Recognition"
				sectionOneDesc = "Ready-to-use patterns that can aid your trading. 15+ ready-made patterns free to use I Browse pattern for historic period and more"
				sectionSecondText = "Market Dashboard"
				sectionSecondDesc = "Market Dashboard empowers investors and traders by providing insights on stock prices, market indices, top gainers and losers, sector performance, and other technical indicators. Which industries and sectors are leading the market, which individual companies within a sector or industry are outperforming others? Quickly assess market conditions and make informed decisions based on comprehensive insights all important indicators."
				buttonTitle= "Get Started"
				tab1Url={prsMock}
				tab2Url={mdbMockup}
				selector1='mdb-1'
				selector2='mdb-2'
				align={IMAGE_ALIGN_RIGHT}
				redirectButtonUrl= "/sign-in"
			/>

			<div className="container">
				<div className="hr" />
			</div>

			<MemoizedFeaturesSection 
				heading = "Get 1:1 coaching, free webinars and more"
				description = "Experience the power of 1:1 coaching, mentorship that are exclusive for capitaltrends members. All geared to make you feel a confident trader!"
				sectionOneText = "1:1 Coaching - signup"
				sectionOneDesc = "Elevate your trading game with personalized 1:1 coaching. Get expert guidance and actionable steps to succeed in the stock market. Sign up now!"
				sectionSecondText = "Get mentorship for Trading"
				sectionSecondDesc = "Gain invaluable mentorship for trading success. Unlock expert guidance and actionable strategies. Start now!"
				sectionThirdText = "Free Webinars, training videos &amp; more..."
				sectionthirdDesc= "Access a wealth of knowledge with our free webinars and training videos. Expand your trading skills with expert insights. Join us today!"
				buttonTitle= "I need 1:1 coach"
				tab1Url={coaching}
				tab2Url={coaching_2}
				tab3Url={coaching_3}
				selector1='coaching-1'
				selector2='coaching-2'
				selector3='coaching-3'
				align={IMAGE_ALIGN_LEFT}
				redirectButtonUrl= "/1-1-Coaching"
			/>

			<OtpVerify openModal={openModal} handleClose={handleClose} phoneNumber={primaryPhone} />
		</>		
	);
}	

export default Home;
