import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CircularProgress from '@mui/joy/CircularProgress';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { BASE_URL } from '../../../constant/index';
import { useAuth } from "../../../context/AuthProvider";

const getISTDate = () => {
    const now = new Date();
    // Get UTC time components and add the IST offset
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60 * 1000); // Convert local time to UTC
    const istOffset = 330 * 60 * 1000; // IST is UTC + 5:30
    const istDate = new Date(utcTime + istOffset);
    return dayjs(istDate);
}

const AddRecommendations = ({
    openAddRecommendationsModal, 
    setAddRecommendationsModalOpen,
    setSuccessMsg,
    setErrorMsg,
    setLoading,
    getRecommendations,
    isLoading,
}) => {
    const { token } = useAuth();
    const [ input, setInput ] = useState({
        tradeType: '',
        stockSymbol: '',
        futuresLotSize: 0,
        recommendedDate: getISTDate(),
        currentPrice: 0,
        openingPrice: 0,
        tradeStrategies: '',
    });
    const [sybolLists, setSymbolList] = useState([]);
    const [lastPrice, setLastPrice] = useState(0);
    const [maxDate, setMaxDate] = useState('');

    const getSymbolsList = async () => {
        const response = await axios.get(BASE_URL+'/getSymbolsList', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        setSymbolList(response?.data?.rows || []);
    }

    useEffect(() => {
        getSymbolsList();
    }, []);

    const handleCloseAddRecommendations = () => setAddRecommendationsModalOpen(false);

    const recommendationsAction = async (e) => {
        e.preventDefault();
        setSuccessMsg('');
        setErrorMsg('');
        if (input.stockSymbol && input.recommendedDate && input.currentPrice && input.tradeStrategies && input.futuresLotSize > 0) {
            setLoading(true);
            try {
                const { data } = await axios.post(BASE_URL+'/postRecommendations', { input: {
                    ...input,
                    lastPrice,
                    recommendedDate: dayjs(input.recommendedDate).format('MMMM D, YYYY h:mm A'),
                } }, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                if(data?.msg) {
                    setSuccessMsg(data.msg);
                    getRecommendations();
                }
                setLoading(false);
            } catch(err) {
			    setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
			    setLoading(false);
		    } finally {
                setAddRecommendationsModalOpen(false);
                setInput({
                    tradeType: '',
                    stockSymbol: '',
                    futuresLotSize: 0,
                    recommendedDate: getISTDate(),
                    currentPrice: 0,
                    openingPrice: 0,
                    tradeStrategies: '',
                });
                setLastPrice(0);
                setMaxDate('');
            }
        }
    }

    const getSymbolLastPrice = async (symbol) => {
        if(!symbol) {
            return;
        }

        setLoading(true);
        try {
            const { data } = await axios.post(BASE_URL+'/getLastClosingPrice', { symbol }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            setLastPrice(data?.closeValue || 0);
            setMaxDate(data?.maxDate || '');
            setInput(prevState => ({
                ...prevState,
                currentPrice: data?.closeValue || 0,
            }));  
        } catch(err) {
            setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
        } finally {
            setLoading(false);
        }
    }

    const onSymbolChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            stockSymbol: event.target.value,
        }));
        getSymbolLastPrice(event.target.value);
    }

    const onRecommendedDateChange = (dateInput) => {
        setInput(prevState => ({
            ...prevState,
            recommendedDate: dayjs(dateInput),
        }));
    }

    const onOpeningPriceChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            openingPrice: event.target.value,
        }))
    }

    const onTradeStrategies = (event) => {
        setInput(prevState => ({
            ...prevState,
            tradeStrategies: event.target.value,
        }));
    }

    const onCurrentPrice = (event) => {
        setInput(prevState => ({
            ...prevState,
            currentPrice: event.target.value,
        }));  
    }

    const onFuturesLotSize = (event) => {
        setInput(prevState => ({
            ...prevState,
            futuresLotSize: event.target.value,
        }));
    }

    const handleTradeType = (event) => {
        setInput(prevState => ({
            ...prevState,
            tradeType: event.target.value,
        }))
    }

    return (
        <Dialog
            open={openAddRecommendationsModal}
            onClose={handleCloseAddRecommendations}
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogContent>
                <DialogContentText component={'div'}>
                    <form onSubmit={recommendationsAction} >
                        <Stack spacing={4}>
                            <FormControl fullWidth>
                                <InputLabel id="tradeType">Trade Type</InputLabel>
                                <Select
                                    labelId="tradeType"
                                    id="tradeType"
                                    value={input.tradeType}
                                    label="Trade Type"
                                    onChange={handleTradeType}
                                >
                                    <MenuItem value=''>Please select</MenuItem>
                                    <MenuItem value='Buy'>Buy</MenuItem>
                                    <MenuItem value='Sell'>Sell</MenuItem>
                                </Select>
                            </FormControl>    

                            <Autocomplete
                                id="symbol"
                                freeSolo
                                options={sybolLists}
                                renderInput={(params) => <TextField {...params} label="Stock Symbol" />}
                                onBlur={onSymbolChange}
                            />
    
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker label="Recommended Date & Time" onChange={(newValue) => onRecommendedDateChange(newValue)} value={input.recommendedDate}/>
                            </LocalizationProvider>

                            <Stack direction="row" spacing={3}>
                                <TextField 
                                    id="currentPrice"
                                    label="Current Price" 
                                    variant="standard" 
                                    onChange={onCurrentPrice}
                                    value={input.currentPrice}
                                    sx={{ width: '250px' }}
                                />

                                <TextField 
                                    id="lastPrice"
                                    label="Last Price" 
                                    variant="standard"
                                    value={lastPrice}
                                    sx={{ width: '250px' }}
                                    disabled
                                />
                            </Stack>
                            {
                                maxDate && (
                                    `(Above both prices are from ${maxDate})`
                                )
                            }
                            
                            <Stack direction="row" spacing={3}>
                                <TextField 
                                    id="openingPrice"
                                    label="Opening Price" 
                                    variant="standard" 
                                    onChange={onOpeningPriceChange}
                                    value={input.openingPrice}
                                    sx={{ width: '250px' }}
                                />

                                <TextField 
                                    id="futuresLotSize"
                                    label="Futures Lot Size" 
                                    variant="standard" 
                                    onChange={onFuturesLotSize}
                                    value={input.futuresLotSize}
                                    sx={{ width: '250px' }}
                                    helperText="*Lot Size cannot be zero"
                                />
                            </Stack>

                            <TextField 
                                id="tradeStrategies"
                                label="Trade Strategies"
                                onChange={onTradeStrategies}
                                variant="standard" 
                            />
                        </Stack>

                        <div className="d-flex mt-3">
                            <button type="submit" className="btn btn-primary mb-4">{isLoading ? (<CircularProgress size="sm" />) : 'Submit'}</button>
                        </div>
                    </form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAddRecommendations}>close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddRecommendations;