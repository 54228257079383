export const IMAGE_ALIGN_RIGHT = "right";

export const IMAGE_ALIGN_LEFT  = "left";

export const BASE_URL = "https://capitaltrends.in";
// export const BASE_URL = "http://localhost:4100";
// export const BASE_URL = "http://beta.capitaltrends.in:4101";

export const MARKET_DASHBOARD_COMPONENTS = [
    { key:1, value: 'Nifty 50', label: 'Top 8 Nifty' },
    { key:2, value: 'Sectoral Indices', label: 'Top 8 Sectors' },
    { key:3, value: 'NSE-ALL', label: 'NSE-ALL' },
    { key:4, value: 'Nifty 100', label: 'Top 8 Nifty-100' },
    { key:5, value: 'Nifty 200', label: 'Top 8 Nifty-200' },
    { key:6, value: 'Nifty 500', label: 'Top 8 Nifty-500' },
    { key:7, value: 'Nifty Auto', label: 'Top 8 Auto' },
    { key:8, value: 'Nifty Energy', label: 'Top 8 Energy' },
    { key:9, value: 'Nifty-ETF', label: 'Top 8 Nifty-ETF' },
    { key:10, value: 'Nifty Financial Serv', label: 'Top 8 Finance' },
    { key:11, value: 'Nifty FMCG', label: 'Top 8 FMCG' },
    { key:12, value: 'Nifty-GOLD-ETF', label: 'Top 8 Gold' },
    { key:13, value: 'Nifty Infrastructure', label: 'Top 8 Infra' },
    { key:14,  value: 'Nifty IT', label: 'Top 8 IT' },
    { key:15, value: 'Nifty Media', label: 'Top 8 Media' },
    { key:16, value: 'Nifty Metal', label: 'Top 8 Metal' },
    { key:17, value: 'Nifty Midcap', label: 'Top 8 Midcap' },
    { key:18, value: 'Nifty MNC', label: 'Top 8 MNC' },
    { key:19, value: 'Nifty Junior', label: 'Top 8 Junior' },
    { key:20, value: 'NIFTY Midcap 50', label: 'Top 8 Midcap' },
    { key:21, value: 'Nifty Pharma', label: 'Top 8 Pharma' },
    { key:22, value: 'Nifty PSE', label: 'Top 8 PSE' },
    { key:23, value: 'Nifty PSU Bank', label: 'Top 8 PSU Bank' },
    { key:24, value: 'Nifty Realty', label: 'Top 8 Realty' },
    { key:25, value: 'Nifty Service Sector', label: 'Top 8 Service' },
    { key:26, value: 'Nifty Smallcap', label: 'Top 8 SmallCap' },
    { key:27, value: 'IPO', label: 'Top 8 Nifty-IPO' },
    { key:28, value: 'Broad Market Indices', label: 'Top 8 Major Indices' },
    { key:29, value: 'Nifty Bank', label: 'Top 8 Bank Nifty' },
    { key:30, value: 'Nifty Thematic Indices', label: 'Top 8 Thematic Indices' },
];

export const MARKET_DASHBOARD_DAYS = [
    { key:1, value: '1-DAY', label: '1-Day' },
    { key:2, value: '1-WEEK', label: '1-Week' },
    { key:3, value: '3-MNTH', label: '3-Month' },
    { key:4, value: '6-MNTH', label: '6-Month' },
    { key:5, value: '1-YR', label: '1-Year' },
    { key:6, value: '3-YR', label: '3-Year' },
    { key:7, value: '5-YR', label: '5-Year' },
    // { key:8, value: '7-YR', label: '7-Year' },
    { key:9, value: 'YTD', label: 'YTD' },
]

export const PATTERN_RECOGNITION_DAILY_PATTERNS = [
    { key:1, value: '52WH', label: '52 Week High' },
    { key:2, value: '52WL', label: '52 Week Low' },
    { key:3, value: '52WVH', label: '52 Week Volume High' },
    { key:4, value: 'NR', label: 'Negative Reversal' },
    { key:5, value: 'PR', label: 'Positive Reversal' },
    { key:6, value: 'GU', label: 'Gapping Up' },
    { key:7, value: 'GD', label: 'Gapping Down' },
    { key:8, value: '50MAVGP', label: '50 MAVG: +5% Range' },
    { key:9, value: '50MAVGN', label: '50 MAVG: -5% Range' },
    { key:10, value: '200MAVGP', label: '200 MAVG: +5% Range' },
    { key:11, value: '200MAVGN', label: '200 MAVG: -5% Range' },
    // { key:12, value: 'GOLDEN', label: 'Golden Cross' },
    // { key:13, value: 'DEATH', label: 'Death Cross' },
];

export const PATTERN_RECOGNITION_ADMIN_PATTERNS = [
    { key:14, value: 'PERFECT_ALIGNMENT', label: 'Perfect Alignment' },
    { key:15, value: 'PERFECT_ALIGNMENT_NEW', label: 'Perfect Alignment New' },
    { key:16, value: 'INSIDE_DAY', label: 'Inside day' },
    { key:17, value: 'OUTSIDE_DAY', label: 'Outside day' },
    { key:18, value: 'UP5W', label: 'Up 5 week' },
]

export const PATTERN_RECOGNITION_WEEKLY_PATTERNS = [
    { key:1, value: '3WTP', label: '3 Week Tight Pattern' },
    { key:2, value: '5WTP', label: '5 Week Tight Pattern' },
]

export const PATTERN_RECOGNITION_COLUMNS = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'SYMBOL', headerName: 'Stock Symbol', width: 170 },
    { field: 'INDEX_SECTOR_CODE', headerName: 'Index Sector', width: 170 },
    { field: 'CLOSE_VALUE', headerName: 'Latest Close Value', type: 'number', width: 180, align: 'center' },
    { field: 'VOLUME', headerName: 'Volume', type: 'number', width: 130 },
    { field: 'AVG_VOLUME_50_DAY', headerName: '50 Day Avg. Volume', type: 'number', width: 190, align: 'center' },
];

export const PATTERN_RECOGNITION_VISIBLE_COLUMNS = ['SYMBOL', 'INDEX_SECTOR_CODE', 'CLOSE_VALUE', 'VOLUME', 'AVG_VOLUME_50_DAY'];

export const ADMIN_USERS = ['parvesh_sharma@hotmail.com', 'saurabh.gaur01@gmail.com'];