export const sortTrades = (trades, key) => trades.sort((a, b) => new Date(b[key]) - new Date(a[key]));

export const getSortedNotes = (selectedActiveRow) => {
    const getAllNotes = selectedActiveRow[0].TRADE_STRATEGIES.split(',');
    const getAllDates = selectedActiveRow[0].CREATED_DATE.split(',');
    const formattedNotes = getAllDates.map((date, index) => {
        return { createdDate: date, notes: getAllNotes[index] };
    });
    const sortedNotes = sortTrades(formattedNotes, 'createdDate');
    return sortedNotes;
}